/* ******************  slider1 ********************* */

.slides {
  position: relative;
}

.slide {
  display: none;
  transition: opacity 0.5s ease-in-out;
}

.slide.active {
  display: block;
}

.slide img {
  width: 100%;
  height: 460px;
  /* max-height: 200px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.slides {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide {
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.slide.active {
  opacity: 1;
}

button {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* ****************************** Home3-panel1 ***************************** */

.Home3-panel1-container {
  height: 450px;
  width: 100%;
}

.Home3-panel1-container h3 {
  text-align: center;
  padding-top: 60px;
  font-size: 20px;
  color: #FF593C;
}

.Home3-panel1-container h1 {
  text-align: center;
  padding-top: 10px;
}

.Home3-panel1 {
  display: flex;
  height: 310px;
  width: 95%;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
}

.Home3-panel1-box1,
.Home3-panel1-box2,
.Home3-panel1-box3,
.Home3-panel1-box4 {
  height: 180px;
  display: flex;
  width: 280px;
  background-color: rgb(226, 223, 223);
  margin-top: 70px;
}

.Home3-panel1 img {
  height: 80px;
  width: 100px;
  margin-top: 45px;
}

.Home3-panel1 p {
  font-size: 25px;
  margin-top: 27px;
}

/* ****************************** Home3-panel2 ***************************** */

.Home3-panel2-container {
  display: flex;
  justify-content: space-between;
  height: 540px;
  width: 100%;
}

.Home3-panel2-left {
  height: 540px;
  width: 600px;
  border-radius: 50%;
  background-color: rgb(247, 245, 245);
  margin-left: 65px;
}

.Home3-panel2-left img {
  height: 540px;
  width: 600px;
  background-size: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Home3-panel2-right {
  height: 540px;
  width: 670px;
}

.Home3-panel2-right h3 {
  font-size: 15px;
  padding-top: 50px;
  color: #FF593C;
}

.Home3-panel2-right h1 {
  width: 80%;
}

.Home3-panel2-right p {
  width: 90%;
  font-size: 23px;
}

/* ****************************** Home3-panel3 ***************************** */

.Home3-panel3-container {
  height: 450px;
  width: 100%;
}

.Home3-panel3-container h3 {
  text-align: center;
  padding-top: 60px;
  font-size: 20px;
  color: #FF593C;
}

.Home3-panel3-container h1 {
  text-align: center;
  padding-top: 10px;
}

.Home3-panel3-container h2 {
  font-size: 18px;
  text-align: center;
  color: rgb(83, 80, 80);
  padding-top: 20px;
}

.Home3-panel3 {
  display: flex;
  height: 310px;
  width: 95%;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
}

.Home3-panel3-box1,
.Home3-panel3-box2,
.Home3-panel3-box3,
.Home3-panel3-box4 {
  height: 180px;
  display: flex;
  width: 290px;
  background-color: rgb(226, 223, 223);
  margin-top: 70px;
}

.Home3-panel3 img {
  height: 60px;
  width: 80px;
  margin-top: 5px;
}

.Home3-panel3 p {
  font-size: 15px;
  margin-top: 27px;
}

/* ****************************** Home3-panel4 ***************************** */

.Home3-panel4-container {
  display: flex;
  justify-content: space-between;
  height: 540px;
  width: 100%;
  margin-top: 100px;
}

.Home3-panel4-right {
  height: 540px;
  width: 600px;
  border-radius: 50%;
  margin-left: 65px;
}

.Home3-panel4-right img {
  height: 540px;
  width: 400px;
  background-size: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Home3-panel4-left {
  height: 540px;
  width: 670px;
  margin-left: 40px;
}

.Home3-panel4-left h3 {
  font-size: 15px;
  padding-top: 50px;
  color: #FF593C;
}

.Home3-panel4-left h1 {
  width: 80%;
}

.Home3-panel4-left p {
  width: 90%;
  font-size: 23px;
}

/* ****************************** Home3-panel5 ***************************** */

.Home3-panel5-container {
  height: 670px;
  width: 100%;
}

.Home3-panel5-container h1 {
  text-align: center;
  padding-top: 50px;
}

.Home3-panel5-container iframe {
  width: 1180px;
  height: 515px;
  margin-left: 85px;
  margin-top: 17px;
}

/* ************************* Home-footer1 ************************************ */

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  height: unset;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.footer-section {
  margin: 10px;
  min-width: 200px;
}

.footer-section h2 {
  margin-bottom: 10px;
  font-size: 30px;
  padding-right: 95px;
}

.footer-section a {
  font-size: 14px;
  display: table-row;
  line-height: 40px;
}

.footer-section h2:hover {
  text-decoration: underline;
}

/* ************************* Home-footer2 ************************************ */

.footer2 {
  height:
    50px;
  width: 100%;
  background-color: #474646;
  color: white;
  padding-left: 50px;
  padding-top: 10px;
}


/* ************************************************************************************************************** */

@media screen and (max-width:600px) {
  .footer-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: start;
    flex-direction: column;

    a {
      display: flex;
      align-items: start;
    }
  }

  .Home3-panel1 {
    flex-direction: column;
    margin: 0px !important;
    height: auto;
    align-items: center;
  }

  .Home3-panel1-container {
    height: auto !important;
  }

  .Home3-panel2-container {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .Home3-panel2-left {
    /* padding-left: 0px; */
    margin-left: 0px !important;
    flex-direction: column;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .Home3-panel2-right {
    width: 100%;
    padding: 10px 15px;
    height: auto;
  }

  .Home3-panel2-right h3 {
    font-size: 20px;
  }

  .Home3-panel3-container {
    height: auto;
  }

  .Home3-panel3-container h3 {
    padding-top: 0px;
  }

  .Home3-panel3-container h2 {
    font-size: 19px;
    padding: 10px 15px;
  }

  .Home3-panel3 {
    padding: 0px;
    flex-direction: column;
    height: auto;
  }

  .Home3-panel4-container {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 20px 15px;
  }

  .Home3-panel4-left {
    width: 100%;
    height: auto;
    margin-left: 0px !important;
  }

  .Home3-panel4-right {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    img {
      height: auto;
      width: 200px;
    }
  }

  .Home3-panel4-right {
    width: 100%;

  }


  .Home3-panel5-container {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;

    h1 {
      padding-top: 0px;
    }

    iframe {
      width: 240px;
      height: auto;
      margin: 0px;
    }
  }


  .slide img {
    width: 100%;
    height: 460px;
    max-height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .Home3-panel3 {
    margin-left: 0px;
    width: 100%;
    align-items: center;
  }
}