/* ************************ Home2-panel1 ****************************** */

.Home2-panel1 img {
  height: 550px;
  width: 100%;
}

.Home2-panel1-heading1 {
  height: auto;

}

.Home2-panel1-heading1 h1 {
  height: auto;
  width: 35%;
  margin-top: -480px;
  margin-left: 30px;
  font-size: 55px;
  font-weight: bold;
  line-height: 90px;
}

.Home2-panel1-heading1 button {
  margin-top: 20px;
  margin-left: 30px;
  font-size: 25px;
  background-color: #ebb22c;
  position: relative;
  color: #2b2a2a;
  font-weight: bold;
  border-radius: 5px;
}

.Home2-panel1-heading1 button:hover {
  background-color: #4482c0;
  color: white;
}

/* ************************ Home2-panel2 ****************************** */

.Home2-panel2-container {
  height: 580px;
  width: 90%;
  border: 2px solid rgb(231, 225, 225);
  margin-top: 170px;
  margin-left: 60px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, .2) 0px 7px 9px;
}

.Home2-panel2-first img {
  height: 90px;
  width: 40%;
  margin-top: 50px;
  margin-left: 350px;
}

.Home2-panel2-second {
  text-align: center;
}

.Home2-panel2-second h2 {
  font-size: 35px;
}

.Home2-panel2-second p {
  font-size: 20px;
  color: rgb(80, 77, 77);
}

.Home2-panel2-third {
  height: 260px;
  width: 100%;
  /* background-color: rgb(136, 136, 180); */
  display: flex;
  justify-content: space-evenly;
}

.Home2-panel2-box1,
.Home2-panel2-box2,
.Home2-panel2-box3 {
  height: 220px;
  margin-top: 30px;
  width: 340px;
  border: 0.1px solid rgb(212, 205, 205);
  border-radius: 15px;
  background-color: #dae8f5;
  box-shadow: rgba(0, 0, 0, .2) 0px 7px 9px;
  /* background-color: chocolate; */

}

.Home2-panel2-box1 img,
.Home2-panel2-box2 img,
.Home2-panel2-box3 img {
  height: 60px;
  width: 60px;
  margin-left: 130px;
  margin-top: 30px;
}

.Home2-panel2-box1 h2,
.Home2-panel2-box2 h2,
.Home2-panel2-box3 h2 {
  text-align: center;
}

.Home2-panel2-box1 p,
.Home2-panel2-box2 p,
.Home2-panel2-box3 p {
  text-align: center;
}

.Home2-panel2-box1 img:hover,
.Home2-panel2-box2 img:hover,
.Home2-panel2-box3 img:hover {
  height: 70px;
  width: 70px;
}

/* ************************ Home2-panel3 ****************************** */

.Home2-panel3-container {
  margin-top: 65px;
  height: 790px;
  width: 100%;
  background-color: #4482c0;
}

.Home2-panel3-container h1 {
  color: white;
  text-align: center;
  padding-top: 50px;
  font-size: 40px;
}

.Home2-panel3-icon img {
  height: 50px;
  gap: 50%;
  margin-top: 30px;
}

.Home2-panel3-icon {
  display: flex;
  justify-content: space-evenly;
}

.Home2-panel3-icon img:hover {
  height: 50px;
  width: 70px;
}

.Home2-panel3-con2 {
  display: flex;
  margin-top: 40px;
  height: 490px;
  width: 96%;
  background-color: white;
  margin-left: 27px;
}

.Home2-panel3-con2-left iframe {
  margin-left: 25px;
  margin-top: 30px;
}

.Home2-panel3-con2-right {
  position: relative;
  height: 420px;
  width: 615px;
  margin-left: 25px;
  margin-top: 30px;
}

.Home2-panel3-con2-right h1 {
  margin-top: -30px;
  color: #4482c0;
  margin-left: 0px;
  font-size: 40px;
}

.Home2-panel3-con2-right p {
  font-size: 19px;
}

.Home2-panel3-con2-right button {
  color: black;
  font-weight: bold;
  margin-top: 126px;
  height: auto;
  width: 245px;
  font-size: 22px;
  background-color: #f9bf34;
}

.Home2-panel3-con2-right-button1 {
  margin-left: 30px;
}

.Home2-panel3-con2-right-button2 {
  margin-left: 290px;
}

.Home2-panel3-con2-right button:hover {
  background-color: #4482c0;
  color: white;
}

/* ************************ Home2-panel4 ****************************** */

.Home2-panel4-container {
  height: 470px;
  width: 100%;
  /* background-color: aqua; */
  text-align: center;
}

.Home2-panel4-container h1 {
  font-size: 35px;
  padding-top: 50px;
}

.Home2-panel4-container p {
  font-size: 19px;
  padding-top: 10px;
}

.Home2-panel4-img {
  padding-top: 50px;
  display: flex;
  justify-content: space-evenly;
}

.Home2-panel4-image-name {
  margin-top: 13px;
  font-size: 25px;
  font-weight: bolder;
  color: rgb(98, 98, 161);
}

/* ************************ Home2-panel5 ****************************** */

.Home2-panel5-container {
  height: 735px;
  width: 100%;
  background-color: #f9bf34;
  text-align: center;
}

.Home2-panel5-container h1 {
  padding-top: 60px;
}

.Home2-panel5-container p {
  font-size: 20px;
  width: 90%;
  padding-left: 60px;
  padding-top: 10px;
}

.Home2-panel5-imagebox {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
}

.box {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  width: calc(20% - 16px);
  background-color: wheat
}

.box-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.box-name {
  font-size: 1.2rem;
  margin: 8px 0;
}

.box-paragraph {
  font-size: 1rem;
  color: #666;
}

.box-image:hover {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.7;
}

/* ************************ Home2-panel6 ****************************** */

.Home2-panel6-container {
  padding: 20px 15px;
  height: auto;
  width: 100%;
  /* background-color: aqua; */
  position: absolute;
}

.Home2-panel6-container h1 {
  font-size: 35px;
  padding-left: 20px;
}

.Home2-panel6-container p {
  font-size: 18px;
  padding-left: 20px;
  width: 80%;
}

.Home2-panel6-container a {
  font-size: 22px;
  padding-left: 20px;
  line-height: 0%;
  color: #4582c1;
  font-style: italic;
  text-decoration: underline;
}

.Home2-panel6-container button {
  position: static;
  height: 53px;
  width: 180px;
  background-color: #f9bf34;
  color: black;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 20px;
}

.Home2-panel6-container button:hover {
  background-color: #4582c1;
  color: white;
}

/* ************************ Home2-panel7 ****************************** */

.Home2-panel7-container {
  width: 90%;
  height: 60px;
  overflow: hidden;
  position: relative;
  background: white;
  margin-top: 460px;
  margin-left: 65px;
}

.marquee-wrapper {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.marquee {
  display: flex;
  width: calc(200%);
  animation: marquee 20s linear infinite;
  gap: 50px;
}

.marquee img {
  height: 60px;
  width: auto;
  margin-right: 15px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* ************************ Home2-panel8 ****************************** */

.Home2-panel8-container {
  margin-top: 20px;
  height: 400px;
  width: 100%;
  background-image: url("https://www.vision2020.in/wp-content/uploads/2024/06/about-vision.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Home2-panel8-container h1 {
  text-align: center;
  font-size: 60px;
  padding-top: 80px;
  color: #4582c1;
}

.Home2-panel8-container p {
  text-align: center;
  font-size: 30px;
  color: #4582c1;
}

/* ************************ Home2-panel9 ****************************** */

.Home2-panel9-container {
  height: 660px;
  width: 94%;
  display: flex;
  justify-content: space-between;
  margin-left: 45px;
  margin-top: -60px;
  margin-bottom: 20px;
  background-color: white;
}

.Home2-panel9-left {
  height: 660px;
  width: 450px;
  background-color: white;
  background-image: url("https://www.eyeqindia.com/wp-content/themes/eyeqindia/assets/images/img_hp_book_appointment2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Home2-panel9-right {
  height: 660px;
  width: 800px;
  background-color: white;
}

.Home2-panel9-right h1 {
  font-weight: bold;
  font-size: 25px;
  color: #c99724;
  padding-top: 20px;
}

.Home2-panel9-right p {
  padding-top: 6px;
  font-size: 19px;
  color: #666;
}

/* Basic form styling */
form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Styling for form labels */
form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Styling for input fields and textarea */
form input[type="text"],
form input[type="date"],
form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Styling for checkboxes */
form input[type="checkbox"] {
  margin-right: 5px;
}

/* Error message styling */
.error {
  color: #d9534f;
  font-size: 0.875em;
  margin-top: 5px;
}

/* Styling for submit button */
form button[type="submit"] {
  background-color: #5bc0de;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  position: relative;
  margin-top: 30px;
}

form button[type="submit"]:hover {
  background-color: #31b0d5;
}

/* ************************* Home-footer1 ************************************ */

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  height: unset;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.footer-section {
  margin: 10px;
  min-width: 200px;
}

.footer-section h2 {
  margin-bottom: 10px;
  font-size: 30px;
  padding-right: 95px;
}

.footer-section a {
  font-size: 14px;
  display: table-row;
  line-height: 40px;
}

.footer-section h2:hover {
  text-decoration: underline;
}

/* ************************* Home-footer2 ************************************ */

.footer2 {
  height:
    50px;
  width: 100%;
  background-color: #474646;
  color: white;
  padding-left: 50px;
  padding-top: 10px;
}


/* ************************************************************************************************************** */


@media screen and (max-width:600px) {
  .Home2-panel2-third {
    height: auto;
  }

  .Home2-panel3-container h1 {
    font-size: 25px;
    padding-left: 0;
  }

  .Home2-panel3-container {
    height: auto;
  }

  .Home2-panel3-icon {
    flex-wrap: wrap;
  }

  .Home2-panel3-con2 {
    flex-direction: column;
    height: auto !important;
    margin-left: 0px !important;
    width: 100%;
    align-items: center;
  }

  .Home2-panel3-con2-left {
    iframe {
      width: 90%;
      height: 140px;
    }
  }

  .Home2-panel3-con2-right {
    width: 100%;
    margin-left: 0px !important;
    padding: 10px 15px !important;
  }

  .Home2-panel3-con2-right h1 {
    margin-left: 0px;

    p {
      font-size: 15px;
    }
  }

  .Home2-panel4-container {
    height: auto;
  }

  .Home2-panel4-container h1 {
    font-size: 25px !important;
  }

  .Home2-panel4-img {
    flex-wrap: wrap;
  }

  .Home2-panel5-container {
    flex-direction: column;
    align-items: center;
    height: auto;

    p {
      padding-left: 0px;
      width: 100%;
      padding: 10px 15px;
    }
  }

  .Home2-panel5-imagebox {
    flex-direction: column;
    align-items: center;

    div {
      width: 80%;
    }
  }


  .Home2-panel6-container {
    height: auto;
    position: static;

    h1 {
      font-size: 30px !important;
    }

    p {
      width: 100%;
      height: auto;
    }
  }

  .Home2-panel7-container {
    margin-left: 0px;
    margin-top: 0px;
  }

  .Home2-panel8-container h1 {
    font-size: 30px;
    padding-top: 0px;
  }

  .Home2-panel8-container p {
    font-size: 19px;
    padding-top: 0px;
  }

  .Home2-panel9-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-left: 0px;
    width: 100%;
  }

  .Home2-panel9-left {
    width: 100%;
  }

  .Home2-panel9-right {
    height: auto;
    width: 100%;
    padding: 20px 15px;
  }

  .Home2-panel7-container {
    width: 100%;
    overflow: hidden;
  }

  .marquee-wrapper {
    margin: 0px !important;
  }

  .Home2-panel8-container {
    height: 186px;
  }
}