/* ********************** GalleryOptical-panel1 **************************** */

.GalleryOptical-panel1 img {
    height: 460px;
    width: 100%;
}

/* ********************** GalleryOptical-panel2 **************************** */

.GalleryOptical-panel2 {
    display: flex;
    height: 50px;
    width: 90%;
    margin-top: 50px;
    margin-left: 60px;

}

.GalleryOptical-panel2 h2 {
    color: brown;
}

.GalleryOptical-panel2 p {
    padding-left: 130px;
}

/* ********************** GalleryOptical-panel3 **************************** */

.GalleryOptical-panel3 {
    text-align: center;
    padding-top: 50px;
    height: 250px;
    width: 100%;
}


.GalleryOptical-panel3-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;

}

.GalleryOptical-panel3-box1,
.GalleryOptical-panel3-box2,
.GalleryOptical-panel3-box3,
.GalleryOptical-panel3-box4,
.GalleryOptical-panel3-box5 {
    height: 250px;
    width: 250px;
    background-color: white;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease;
}

.GalleryOptical-panel3-box1 {
    background-image: url("https://www.opticalh.com/252942-large_default/ray-ban-meta-wayfarer-rb4006-601sm1-50-22.jpg");
}

.GalleryOptical-panel3-box2 {
    background-image: url("https://www.opticalh.com/249903-large_default/ray-ban-meta-wayfarer-rb4006-60171-50-22.jpg");
}

.GalleryOptical-panel3-box3 {
    background-image: url("https://www.opticalh.com/252944-large_default/ray-ban-meta-wayfarer-rb4006-601sb-50-22.jpg");
}

.GalleryOptical-panel3-box4 {
    background-image: url("https://www.opticalh.com/249903-large_default/ray-ban-meta-wayfarer-rb4006-60171-50-22.jpg");
}

.GalleryOptical-panel3-box5 {
    background-image: url("https://www.opticalh.com/267091-large_default/ray-ban-meta-skyler-rb4010-670013-52-20.jpg");
}


.GalleryOptical-panel3-box1:hover,
.GalleryOptical-panel3-box2:hover,
.GalleryOptical-panel3-box3:hover,
.GalleryOptical-panel3-box4:hover,
.GalleryOptical-panel3-box5:hover:hover {
    background-size: 110%;
}

/* *********************** GalleryOptical-panel4 ****************************** */

.GalleryOptical-panel4 {
    text-align: center;
    padding-top: 50px;
    height: 250px;
    width: 100%;
}


.GalleryOptical-panel4-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.GalleryOptical-panel4-box1,
.GalleryOptical-panel4-box2,
.GalleryOptical-panel4-box3,
.GalleryOptical-panel4-box4,
.GalleryOptical-panel4-box5 {
    height: 250px;
    width: 250px;
    background-color: white;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease;
}

.GalleryOptical-panel4-box1 {
    background-image: url("https://www.opticalh.com/267170-large_default/ray-ban-meta-wayfarer-rb4008-6705p5-53-22.jpg");
}

.GalleryOptical-panel4-box2 {
    background-image: url("https://www.opticalh.com/267143-large_default/ray-ban-meta-headliner-rb4009-6705zx-50-23.jpg");
}

.GalleryOptical-panel4-box3 {
    background-image: url("https://www.opticalh.com/267129-large_default/ray-ban-meta-skyler-rb4010-670683-52-20.jpg");
}

.GalleryOptical-panel4-box4 {
    background-image: url("https://www.opticalh.com/267091-large_default/ray-ban-meta-skyler-rb4010-670013-52-20.jpg");
}

.GalleryOptical-panel4-box5 {
    background-image: url("https://www.opticalh.com/267143-large_default/ray-ban-meta-headliner-rb4009-6705zx-50-23.jpg");
}


.GalleryOptical-panel4-box1:hover,
.GalleryOptical-panel4-box2:hover,
.GalleryOptical-panel4-box3:hover,
.GalleryOptical-panel4-box4:hover,
.GalleryOptical-panel4-box5:hover:hover {
    background-size: 110%;
}

/* *********************** GalleryOptical-panel5 ****************************** */

.GalleryOptical-panel5 {
    text-align: center;
    padding-top: 50px;
    height: 250px;
    width: 100%;
}


.GalleryOptical-panel5-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

.GalleryOptical-panel5-box1,
.GalleryOptical-panel5-box2,
.GalleryOptical-panel5-box3,
.GalleryOptical-panel5-box4,
.GalleryOptical-panel5-box5 {
    height: 250px;
    width: 250px;
    background-color: white;
    border-radius: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease;
}

.GalleryOptical-panel5-box1 {
    background-image: url("https://www.opticalh.com/267116-large_default/ray-ban-meta-skyler-rb4010-6019a-52-20.jpg");
}

.GalleryOptical-panel5-box2 {
    background-image: url("https://www.opticalh.com/267103-large_default/ray-ban-meta-skyler-rb4010-601mf-52-20.jpg");
}

.GalleryOptical-panel5-box3 {
    background-image: url("https://www.opticalh.com/258132-large_default/ray-ban-meta-wayfarer-rb4006-601st3-50-22.jpg");
}

.GalleryOptical-panel5-box4 {
    background-image: url("https://www.opticalh.com/258136-large_default/ray-ban-meta-wayfarer-rb4008-601sm1-53-22.jpg");
}

.GalleryOptical-panel5-box5 {
    background-image: url("https://www.opticalh.com/267103-large_default/ray-ban-meta-skyler-rb4010-601mf-52-20.jpg");
}


.GalleryOptical-panel5-box1:hover,
.GalleryOptical-panel5-box2:hover,
.GalleryOptical-panel5-box3:hover,
.GalleryOptical-panel5-box4:hover,
.GalleryOptical-panel5-box5:hover:hover {
    background-size: 110%;
}

/* ********************** GalleryOptical-panel6 **************************** */

.GalleryOptical-panel6 {
    margin-top: 80px;
    margin-left: 140px;
    text-align: center;
    height: 600px;
    width: 80%;
    /* background-color: aquamarine; */
}

.GalleryOptical-panel6 h2 {
    font-size: 21px;
}

.GalleryOptical-panel6 p {
    font-size: 15px;
    color: #7a7a7a !important;
}

@media screen and (max-width: 600px) {
    .GalleryOptical-panel1 {
        img {
            width: 100%;
            height: auto;
        }
    }

    .GalleryOptical-panel3 {
        text-align: center;
        padding-top: 50px;
        height: auto;
        width: 100%;
    }

    .GalleryOptical-panel2 {
        flex-direction: column;
        display: flex;
        height: auto;
        width: auto;
        margin-top: 20px;
        padding-left: 10px !important;
        margin-left: 0px;

        p {
            padding-left: 0px;
        }
    }

    .GalleryOptical-panel3-container {
        flex-direction: column;
        align-items: center;
    }

    .GalleryOptical-panel4 {
        padding-top: 0px;
    }

    .GalleryOptical-panel4-container {
        flex-direction: column;
        align-items: center;
    }

    .GalleryOptical-panel5 {
        height: auto;
    }

    .GalleryOptical-panel6 {
        height: auto;
        margin-top: 80px;
        margin-left: 0px;
        text-align: center;
        height: auto;
        width: 100%;
    }

    .GalleryOptical-panel6 {
        padding-top: 0px;
        margin-top: 0px;
        padding: 0px 20px;
    }
}