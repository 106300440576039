/*  **  **  **  top-bar **  **  **  **  */

.topbar {
  display: flex;
  background-color: #333;
  color: white;
  height: auto;
  padding: 10px 0;
  border-top: 1px solid #2E6B5F;
}

.top-para {
  display: inline-block;
  margin-right: -50px;
  font-size: 15px;
  margin-left: 40px;
  padding-top: 9px;
}

.social-media {
  gap: 15px;
  /* Space between icons */
  padding-left: 690px;
}

.social-icon img {
  width: 40px;
  /* Adjust size as needed */
  height: auto;
  transition: transform 0.3s ease;
}

.social-icon img:hover {
  transform: scale(1.1);
  /* Slight zoom effect on hover */
}

@media (max-width: 768px) {
  .topbar {
    display: none;
    /* Hides the topbar on mobile devices */
  }
}

/* src/components/Navbar.css */
/* General Navbar Styling */
.navbar {
  background-color: #968e8e;
  color: white;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-links>li {
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 14px 20px;
  display: block;
}

/* .nav-links li:hover > a {
  background-color: #575757;
} */

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 200px;
  /* Adjust width as needed */
}

.dropdown-menu li a {
  padding: 10px;
  color: black;
}

/* .dropdown-menu li a:hover {
  background-color: #575757;
} */

.dropdown:hover .dropdown-menu {
  display: block;
}

@media screen and (max-width:600px) {
  .nav-links {
    display: none;
  }

  .Home2-panel1 img {
    height: 160px !important;
  }

  .Home2-panel1-heading1 {
    padding: 20px 15px;
  }

  .Home2-panel1-heading1 h1 {
    height: auto !important;
    width: 100% !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    font-size: 50px !important;
    font-weight: bold !important;
    font-size: 40px !important;
    line-height: 90px !important;
  }

  .Home2-panel2-container {
    height: auto;
    width: 90%;
    border: 2px solid rgb(231, 225, 225);
    margin-top: 0px !important;
    margin-left: 30px !important;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, .2) 0px 7px 9px;
  }

  .Home2-panel2-first {
    img {
      margin-left: 0px !important;
      width: 100% !important;
      margin-top: 20px !important;
    }
  }

  .Home2-panel2-container {

    height: auto !important;
  }

  .Home2-panel2-second h2 {
    font-size: 25px !important;
  }

  .Home2-panel2-second p {
    font-size: 18px !important;
  }

  .Home2-panel2-third {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-bottom: 10px;

    div {
      width: 90%;
    }
  }



  .nav-links-mobile {
    gap: 10px;

    a {
      list-style: none;
      color: white;
    }

    li {
      padding-right: 20px;

      a {
        text-decoration: none;
      }
    }
  }
}