/* *********************** optical-about-panel1**************************** */

.Optical-About-panel1 {
  height: 30px;
  width: 100%;
  margin-top: 0px;
  display: none;
  padding-left: 100px;
}

/* *********************** optical-about-panel2**************************** */


.Optical-About-panel2 {
  height: auto;
  width: 100%;
  margin-top: 40px;
  margin-left: 32px;
}

.Optical-About-panel2 h1 {
  text-align: center;
  padding-bottom: 20px;
  font-size: 30px;
}

/* *********************** optical-about-panel3 **************************** */

.Optical-About-panel3 {
  height: auto;
  width: 100%;
  margin-top: 40px;
  margin-left: 32px;
}

.Optical-About-panel3 h1 {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

/* *********************** optical-about-panel4 **************************** */

.Optical-About-panel4 {
  height: auto;
  width: 100%;
  margin-top: 40px;
  margin-left: 32px;
  margin-bottom: 25px;
}

.Optical-About-panel4 h1 {
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

/* ************************* Home-footer1 ************************************ */

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  height: 260px;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-section {
  margin: 10px;
  min-width: 200px;
}

.footer-section h2 {
  margin-bottom: 10px;
  font-size: 30px;
  padding-right: 95px;
}

.footer-section a {
  font-size: 14px;
  display: table-row;
  line-height: 40px;
}

.footer-section h2:hover {
  text-decoration: underline;
}

/* ************************* Home-footer2 ************************************ */

.footer2 {
  height:
    50px;
  width: 100%;
  background-color: #474646;
  color: white;
  padding-left: 50px;
  padding-top: 10px;
}


/* ************************************************************************************************************** */


@media screen and (max-width:600px) {
  .Optical-About-panel2 {
    width: 85%;
  }

  .Optical-About-panel3 {
    width: 85%;
  }

  .Optical-About-panel4 {
    width: 85%;
  }
}