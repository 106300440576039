.ContactOptical-panel1 {
    height: 360px;
    width: 93%;
    background-image: url("https://www.gkboptical.com/static/frontend/Gkb/Gkboptical/en_US/images/CSC_1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 51px;
    margin-top: 30px;
}

/* ************************ ContactOptical-panel2 ****************************** */

.ContactOptical-panel2 button {
    margin-top: 230px;
    margin-left: 550px;
    width: 190px;
    font-weight: bold;
    background-color: #5bc0de;
}

/* ************************ ContactOptical-panel3 ****************************** */

.ContactOptical-panel3 {
    height: 140px;
    width: 93%;
    margin-top: 120px;
    margin-left: 51px;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.faq {
    position: static;
}

/* ************************ ContactOptical-panel4 ****************************** */

.ContactOptical-panel4-container {
    height: 160px;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin-left: 128px;
    line-height: 60px;
    margin-bottom: 20px;
}

.ContactOptical-panel4-container img {
    height: 35px;
    width: 35px;
}

/* ************************ ContactOptical-panel5 ****************************** */

.ContactOptical-panel5-cantainer {
    display: flex;
    justify-content: space-evenly;
    height: 300px;
    width: 100%;
}

.ContactOptical-panel5-box1,
.ContactOptical-panel5-box2,
.ContactOptical-panel5-box3 {
    margin-top: 30px;
    height: 180px;
    width: 350px;
    border: 2px solid rgb(226, 220, 220);
    box-shadow: 6px 10px 40px;
    border-radius: 10px;
    position: relative;
    background-color: rgb(250, 248, 248);
}

.ContactOptical-panel5-cantainer h1 {
    font-size: 30px;
    font-weight: bold;
    padding-left: 10px;
    font-family: fangsong;
    position: relative;
    padding-left: 40px;
    padding-top: 17px;
}

.ContactOptical-panel5-cantainer h1::before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS82tr9H8sO60jg68PVJBGLChJvjTkUMEsDsFzVPW3XdgexegGP6wZK2o525llI23E8244&usqp=CAU');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: 8px;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.ContactOptical-panel5-cantainer p {
    font-size: 15px;
    padding-left: 10px;
    color: rgb(78, 75, 75);
}

.ContactOptical-panel5-cantainer a {
    font-size: 22px;
    padding-left: 10px;
    position: relative;
    padding-left: 40px;
}

.ContactOptical-panel5-cantainer a::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrL4Z3CoxOt01vgAQL7Sj8e_-NfCNjEyKBbA&s');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}


@media screen and (max-width:600px) {
    .ContactOptical-panel1 {
        /* height: ; */
        width: 100%;
        background-image: url(https://www.gkboptical.com/static/frontend/Gkb/Gkboptical/en_US/images/CSC_1.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 0px;
        margin-top: 0px;
        height: 115px;
    }

    .ContactOptical-panel3 {
        margin-left: 0px;
        width: 100%;
        margin-top: 0px;
        padding: 20px 15px;
        height: auto;
    }

    .ContactOptical-panel4-container {
        height: auto;
        flex-direction: column;
        margin-left: 0px;
        gap: 10px;
        align-items: center;
        width: 100%;

        div {
            width: 70%;
        }
    }

    .ContactOptical-panel5-cantainer {
        flex-direction: column;
        height: auto;
        align-items: center;
        padding-bottom: 20px;

        div {
            width: 70%;
        }
    }
}