  /* **  **  **  top-bar **  **  **  **  */

  .topbar {
    display: flex;
    background-color: #333;
    color: white;
    height: auto;
    padding: 10px 0;
    border-top: 1px solid #2E6B5F;
  }

  .top-para {
    display: inline-block;
    margin-right: -50px;
    font-size: 15px;
    margin-left: 40px;
    padding-top: 9px;
  }

  .social-media {
    gap: 15px;
    /* Space between icons */
    padding-left: 690px;
  }

  .social-icon img {
    width: 40px;
    /* Adjust size as needed */
    height: auto;
    transition: transform 0.3s ease;
  }

  .social-icon img:hover {
    transform: scale(1.1);
    /* Slight zoom effect on hover */
  }

  @media (max-width: 768px) {
    .topbar {
      display: none;
      /* Hides the topbar on mobile devices */
    }
  }


  /* src/components/Navbar.css */
  /* General Navbar Styling */
  .navbar {
    background-color: #968e8e;
    color: white;
  }

  .nav-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }

  .nav-links>li {
    position: relative;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
    padding: 14px 20px;
    display: block;
  }

  /* .nav-links li:hover > a {
  background-color: #575757;
} */

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 200px;
    /* Adjust width as needed */
  }

  .dropdown-menu li a {
    padding: 10px;
    color: black;
  }

  /* .dropdown-menu li a:hover {
  background-color: #575757;
} */

  .dropdown:hover .dropdown-menu {
    display: block;
  }


  @media screen and (max-width:600px) {
    .dropdown-menu {
      left: -250px;
      top: 0px;

      li {
        padding: 10px 5px;
        text-align: left;
      }
    }

    .a {
      width: 100%;
    }
  }