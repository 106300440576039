/*  **  **  **  top-bar **  **  **  **  */

.topbar {
  display: flex;
  justify-content: space-evenly;
  background-color: #333;
  color: white;
  height: auto;
  padding: 10px 0;
  border-top: 1px solid #2E6B5F;
}

.top-para {
  margin-right: -50px;
  font-size: 15px;
  padding-top: 9px;
}

.social-media {
  display: flex;
  gap: 10px;
}

.social-icon {
  font-size: 25px;
  color: #ffffff;
  text-decoration: none;

}

.social-icon:hover {
  color: #007bff;
}


/* ********************** navbar ******************************************* */

.optical-link {
  font-weight: bold;
  padding-right: 30px;
}

.optical-link:hover {
  text-decoration: underline;
  color: white;
}


.li-service {
  position: relative;
}

.optical_service {
  width: 180px;
  line-height: 40px;
  position: absolute !important;
  background-color: white;
  border-radius: 5px;
  color: black;
  z-index: 20;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  list-style: none;
}



@media screen and (max-width:600px) {
  .panel6-box1 {
    display: none;
  }

  .panel6-box2 {
    margin: auto;
    width: 240px !important;
    height: auto;
  }
}