/* -----------mobile--------------- */

.btn-cross-open {
    display: none;
}

@media screen and (max-width:600px) {
    .nav-links {
        display: none !important;
    }

    .navbar {
        position: relative;
    }

    .nav-links-mobile {
        display: flex;
        align-items: end;
        list-style: none;
        justify-content: start;
        z-index: 20;
        right: 0 !important;
        height: 100vh;
        flex-direction: column;
        top: 0px;
        padding-top: 60px;
        background-color: #968e8e;
    }

    .optical-link {
        padding-right: 10px !important;
    }

    .btn-cross {
        width: auto;
        height: auto;
    }

    .btn-cross {
        display: flex;
        top: 20px;
        position: absolute;
    }

    .btn-cross-open {
        display: flex;
        position: absolute;
        right: 10px;
        top: 28px;
        width: auto;
        height: auto;
        padding: 8px;
        background: transparent;
    }

    .div-black {
        height: 100vh;
        width: 67%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 30;
    }

    .div-optical-menu {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .optical_service {
        left: -228px;
        top: 0px;
    }
}