/* src/Services.css */
.services {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .services h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  .service-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    width: 250px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .service-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .service-card h3 {
    margin: 0.5rem 0;
  }
  
  .service-card p {
    color: #555;
  }
  
  .service-card {
    cursor: pointer;
    text-decoration: none; 
    color: inherit; 
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .service-card:hover {
    background-color: #f0f0f0;
  }
  
  .service-icon {
    font-size: 24px;
  }
  
  /* ************************* Home-footer1 ************************************ */

.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    height: 260px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    margin: 10px;
    min-width: 200px;
  }
  
  .footer-section h2 {
    margin-bottom: 10px;
    font-size: 30px;
    padding-right: 95px;
  }
  
  .footer-section a {
    font-size: 14px;
    display: table-row;
    line-height: 40px;
  }
  
  .footer-section h2:hover {
    text-decoration: underline;
  }
  
  /* ************************* Home-footer2 ************************************ */
  
  .footer2{
    height: 
    50px;
    width: 100%;
    background-color: #474646;
    color: white;
    padding-left: 50px;
    padding-top: 10px;
  }
  
  
  /* ************************************************************************************************************** */
  

  