/* ****************** Home-Nav ****************************** */

.Home-Nav {
  height: 40px;
  width: 100%;
  background-color: white;
  color: black;
}

.Home-Nav-Logo {
  height: 95px;
  margin-top: 3px;
  width: 157px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-image: url("/src/Assets/Images/logo.webp");
}

/* ****************** Home-container ****************************** */

.Home-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

.Home1,
.Home2,
.Home3 {
  width: calc(33.33% - 8px);
  height: 571px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  margin-top: 70px;
  display: flex;
  margin-left: 4px;
  justify-content: center;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: grayscale(100%);
}

.Home1:hover,
.Home2:hover,
.Home3:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.Home-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  width: 98%;
  height: 95%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.Home1 .Home-link {
  background-image: url("/src/Assets/Images/optical.jpg");
  background-position: center;
}

.Home2 .Home-link {
  background-image: url("https://content3.jdmagicbox.com/comp/service_catalogue/ophthalmologists-attr-cataract-evaluation-and-treatment-oph586-3.jpg");
}

.Home3 .Home-link {
  background-image: url("https://dpuhospital.com/images/Services-Images/Ear-Nose-Throat-mobile-new.jpg");
}

.Home-text {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(5, 5, 5);
  font-size: 37px;
  font-weight: bold;
  padding: 8px;
  font-family: Poppins, sans-serif !important;
}

.Home-text:hover {
  color: rgb(63, 61, 61);
}

/* ****************** Responsive Styles ****************************** */

@media (max-width: 400px) {

  .Home1,
  .Home2,
  .Home3 {
    margin-top: 8px !important;
  }
}

@media (max-width: 1200px) {

  .Home1,
  .Home2,
  .Home3 {
    width: calc(50% - 8px);
  }
}

@media (max-width: 768px) {

  .Home1,
  .Home2,
  .Home3 {
    width: calc(100% - 8px);
    height: 300px;
  }

  .Home-text {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .Home-Nav {
    height: 110px;
  }

  .Home-Nav-Logo {
    height: 95px;
  }

  .Home-text {
    font-size: 20px;
  }
}



@media screen and (max-width:600px) {
  .Home-Nav {
    display: block;
  }

  .Home-container {
    height: auto;
    overflow: scroll;

    div {
      width: 250px;
    }
  }
}