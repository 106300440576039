/* General Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #333;
  color: white;
  position: relative;
}

.logo p {
  font-weight: bold;
  font-size: 27px;
  font-family: fangsong;
  margin: 0;
  padding-left: 40px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.nav-links a:hover {
  text-decoration: underline;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
  margin-left: auto;
}

.menu-icon {
  display: block;
  width: 25px;
  height: 2px;
  background: white;
  position: relative;
}

.menu-icon::before,
.menu-icon::after {
  content: "";
  display: block;
  width: 25px;
  height: 2px;
  background: white;
  position: absolute;
  left: 0;
}

.menu-icon::before {
  top: -8px;
}

.menu-icon::after {
  top: 8px;
}

/* ******************  slider1 ********************* */

.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.slides {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide {
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.slide.active {
  opacity: 1;
}

.slide img {
  width: 100%;
  height: auto;
}

button {
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  button {
    display: none;
  }
}

/* ********************** Home-panel1 *************************** */


/* Base styles for larger screens */
.Home-panel1 {
  text-align: center;
  padding-top: 50px;
  height: 600px;
  width: 100%;
}

.Home-panel1 p {
  color: rgb(146, 146, 54);
}

.Home-panel1-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px;
}

.box1,
.box2,
.box3 {
  height: 380px;
  width: 380px;
  background-color: white;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
}

.box1 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1707501265691.jpeg?dpr=1");
}

.box2 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1718020006613.jpeg?dpr=1");
}

.box3 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1718020055201.jpeg?dpr=1");
}

.box1:hover,
.box2:hover,
.box3:hover {
  background-size: 110%;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .Home-panel1 {
    padding-top: 30px;
    height: auto;
    /* Allow height to adjust automatically */
  }

  .Home-panel1-container {
    flex-direction: column;
    /* Stack boxes vertically */
    align-items: center;
    /* Center-align boxes */
  }

  .box1,
  .box2,
  .box3 {
    height: 250px;
    /* Reduce height for smaller screens */
    width: 250px;
    /* Reduce width for smaller screens */
    margin-bottom: 20px;
    /* Add space between stacked boxes */
  }

  .box1:hover,
  .box2:hover,
  .box3:hover {
    background-size: 120%;
    /* Increase hover effect size for better visibility */
  }
}

/* *************************** Home-panel2 ******************************* */

.Home-panel2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* Add some padding around the panel */
  box-sizing: border-box;
  /* Include padding in element's total width and height */
}

.Home-panel-left {
  height: 370px;
  width: 620px;
  margin-left: 38px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://i.ytimg.com/vi/qSTU3hTLmD0/hqdefault.jpg");
}

.Home-panel-right {
  height: 370px;
  width: 580px;
  margin-left: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Home-panel-right h1 {
  color: black;
  margin-top: 0;
  /* Adjust margin for better alignment */
  font-size: 24px;
  /* Adjust font size for better readability */
}

.Home-panel-right p {
  font-size: 16px;
  /* Adjust font size for better readability */
  margin: 10px 0;
  /* Add some spacing between paragraphs and other elements */
}

.Home-panel-right button {
  background-color: #a902ac;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  /* Add padding to the button for better clickability */
  border: none;
  cursor: pointer;
  position: static;
  margin-top: 15px;
  /* Adjust margin as needed */
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) {
  .Home-panel2 {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    /* Adjust padding for smaller screens */
  }

  .Home-panel-left,
  .Home-panel-right {
    width: 100%;
    /* Make panels take full width */
    height: auto;
    /* Allow height to adjust automatically */
    margin: 0;
    /* Remove margins for better fit on mobile */
  }

  .Home-panel-left {
    height: 200px;
    /* Adjust height for smaller screens */
    background-size: cover;
  }

  .Home-panel-right {
    margin-top: 20px;
    /* Add some space between the image and text */
  }

  .Home-panel-right h1 {
    font-size: 20px;
    /* Adjust font size for better readability */
  }

  .Home-panel-right p {
    font-size: 14px;
    /* Adjust font size for better readability */
  }

  .Home-panel-right button {
    padding: 8px 16px;
    /* Adjust padding for smaller screens */
    font-size: 14px;
    /* Adjust font size for better readability */
  }
}


/* ************************* Home-panel3 ************************************ */

.Home-panel3 {
  height: 400px;
  width: 100%;
  margin-top: 30px;
}

.Home-panel3 h2 {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: var(--bs-body-color);
}

.Home-panel3-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
}

.panel3-box1,
.panel3-box2,
.panel3-box3 {
  height: 290px;
  width: 290px;
  background-color: white;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
}

.panel3-box1 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1718016201161.jpeg?dpr=1");
}

.panel3-box2 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1718016226971.jpeg?dpr=1");
}

.panel3-box3 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1718016248672.jpeg?dpr=1");
}

.panel3-box1:hover,
.panel3-box2:hover,
.panel3-box3:hover {
  background-size: 110%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .Home-panel3 {
    height: auto;
    /* Adjust height for mobile */
    margin-top: 15px;
    /* Adjust margin for mobile */
  }

  .Home-panel3 h2 {
    font-size: 30px;
    /* Adjust font size for mobile */
  }

  .Home-panel3-container {
    flex-direction: column;
    /* Stack boxes vertically on mobile */
    gap: 15px;
  }

  .panel3-box1,
  .panel3-box2,
  .panel3-box3 {
    height: 200px;
    /* Adjust height for mobile */
    width: 100%;
    /* Full width on mobile */
    max-width: 400px;
    /* Limit max width on mobile */
    border-radius: 20px;
    /* Adjust border radius for mobile */
  }
}

@media (max-width: 480px) {
  .Home-panel3 h2 {
    font-size: 24px;
    /* Further adjust font size for smaller screens */
  }

  .panel3-box1,
  .panel3-box2,
  .panel3-box3 {
    height: 150px;
    /* Further adjust height for smaller screens */
  }
}


/* ************************* Home-panel4 ************************************ */

/* Base styles */
.Home-panel4 {
  text-align: center;
  padding-top: 50px;
  height: 410px;
  width: 100%;
}

.Home-panel4 p {
  color: rgb(146, 146, 54);
}

.Home-panel4-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px;
  flex-wrap: wrap;
  /* Allow items to wrap onto the next line */
}

.panel4-box1,
.panel4-box2,
.panel4-box3,
.panel4-box4,
.panel4-box5 {
  height: 250px;
  width: 220px;
  background-color: white;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
}

.panel4-box1 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/products/pictures/item/free/resize-w:350/000000000492361378/PJy_XiPIiU-492361378-1.jpg?dpr=1");
}

.panel4-box2 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/products/pictures/item/free/resize-w:350/000000000492338949/y5aPHM3ZlH-492338949-1.jpg?dpr=1");
}

.panel4-box3 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/products/pictures/item/free/resize-w:350/000000000491973844/X2CeEGm3fwK-491973844-1.jpg?dpr=1");
}

.panel4-box4 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/products/pictures/item/free/resize-w:350/000000000492361340/Nzr_Xbtt7H-492361340-1.jpg?dpr=1");
}

.panel4-box5 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/products/pictures/item/free/resize-w:350/000000000491973840/FHOZa5O91U5-491973840-1.jpg?dpr=1");
}

.panel4-box1:hover,
.panel4-box2:hover,
.panel4-box3:hover,
.panel4-box4:hover,
.panel4-box5:hover {
  background-size: 120%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .Home-panel4 {
    height: auto;
    /* Adjust height for smaller screens */
    padding-top: 30px;
  }

  .panel4-box1,
  .panel4-box2,
  .panel4-box3,
  .panel4-box4,
  .panel4-box5 {
    height: 200px;
    /* Adjust height for smaller screens */
    width: 180px;
    /* Adjust width for smaller screens */
    margin: 10px;
    /* Add margin for spacing */
  }

  .Home-panel4-container {
    justify-content: center;
    /* Center items in smaller screens */
  }
}

@media (max-width: 480px) {
  .Home-panel4 {
    padding-top: 20px;
  }

  .panel4-box1,
  .panel4-box2,
  .panel4-box3,
  .panel4-box4,
  .panel4-box5 {
    height: 130px;
    /* Adjust height for extra small screens */
    width: 150px;
    /* Adjust width for extra small screens */
    margin: 5px;
    /* Adjust margin for extra small screens */
  }

  .Home-panel4-container {
    flex-direction: row;
    /* Stack items vertically on very small screens */
    align-items: center;
    /* Center items vertically on very small screens */
  }
}

/* ************************* Home-panel5 ************************************ */

/* Existing desktop styles */
.Home-panel5 {
  text-align: center;
  padding-top: 50px;
  height: 520px;
  width: 100%;
}

.Home-panel5-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 20px;
}

.panel5-box1 {
  height: 450px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
  background-image: url("https://file.hstatic.net/1000074526/article/contact-lens-wear-instructions_8ed5a9d397b346c48215eab159aa1d7c_1024x1024.jpg");
}

.panel5-box1:hover {
  background-size: 120%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .Home-panel5 {
    padding-top: 20px;
    /* Reduce padding on mobile */
    height: auto;
    /* Adjust height to fit content */
  }

  .Home-panel5-container {
    flex-direction: column;
    /* Stack items vertically */
    padding-top: 10px;
    /* Reduce padding on mobile */
  }

  .panel5-box1 {
    height: 300px;
    /* Adjust height for mobile screens */
    width: 100%;
    /* Ensure width is responsive */
  }
}


/* ************************* Home-panel6 ************************************ */

.Home-panel6 {
  height: 400px;
  width: 100%;
  margin-top: 30px;
}

.Home-panel6-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
}

.panel6-box1,
.panel6-box2 {
  height: 290px;
  width: 290px;
  background-color: white;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
}

.panel6-box1 {
  /* background-image: url("C:\Users\Admin\Downloads\Screenshot (1).jpg"); */
}

.panel6-box2 {
  background-image: url("https://cdn.fynd.com/v2/falling-surf-7c8bb8/fyprod/wrkr/company/1076/applications/5ea82cc2b496555b51caafa3/theme/pictures/free/resize-w:450/theme-image-1707221184818.jpeg?dpr=1");
}

.panel6-box1:hover,
.panel6-box2:hover {
  background-size: 110%;
}


@media only screen and (max-width: 768px) {
  .Home-panel6 {
    height: auto;
    margin-top: 20px;
  }

  .Home-panel6-container {
    flex-direction: column;
    gap: 10px;
  }

  .panel6-box1,
  .panel6-box2 {
    /* height: 200px; */
    width: 100%;
  }
}


/* ************************* Home-footer1 ************************************ */

footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  height: unset;
}

.footer-content {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.footer-section {
  margin: 10px;
  min-width: 200px;
}

.footer-section h2 {
  margin-bottom: 10px;
  font-size: 30px;
  padding-right: 95px;
}

.footer-section a {
  font-size: 14px;
  display: table-row;
  line-height: 40px;
  color: white;
  text-decoration: none;
}

.footer-section h2:hover {
  text-decoration: underline;
}

.footer-section a:hover {
  text-decoration: underline;
}

/* ************************* Home-footer2 ************************************ */

.footer2 {
  height: 50px;
  width: 100%;
  background-color: #474646;
  color: white;
  padding-left: 50px;
  padding-top: 10px;
}

/* ************************************************************************************************************** */